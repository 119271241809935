<template>

  <div>
    <div v-if="editWeek && props.diary && allowEdit">  
          
      <template v-if="isLoadedPresaver">
        <br>
        <br>
        <UiMessage
          type="green"
          :button-name="$t('create_week_view_presave_clear')"
          :button-type="'primary'"
          @click-button="presaveClear"
          >
          <b>{{ $t('create_week_view_loaded_from_previous_edit') }}</b>
          <div>
            <span>{{ $t('create_week_view_presave_text1') }}</span>
            {{ $t('create_week_view_presave_text2') }}
          </div>
        </UiMessage>
      </template>


      <WeekEditFazaGerm 
        v-if="editWeek.faza === WeekFaza.Germination" 
        :week="editWeek" 
        :diary="diary" 
        :is-loading-save="isLoadingSave"
        @updateweek="emits('updateWeek')" 
        @saveweek="saveWeek($event)"
        @canceleditweek="emits('cancelEditWeek', $event)" 
        />      
      <WeekEditFazaVeg 
        v-if="editWeek.faza === WeekFaza.Vegetation" 
        :week="editWeek" 
        :diary="diary" 
        :is-loading-save="isLoadingSave"
        @update:faza="changeFazaVeg"
        @updateweek="emits('updateWeek')" 
        @saveweek="saveWeek($event)"
        @canceleditweek="emits('cancelEditWeek', $event)" 
        />      
      <WeekEditFazaFlo 
        v-if="editWeek.faza === WeekFaza.Flowering" 
        :week="editWeek" 
        :diary="diary" 
        :is-loading-save="isLoadingSave"
        @update:faza="changeFazaFlo" 
        @updateweek="emits('updateWeek')" 
        @saveweek="saveWeek($event)"
        @canceleditweek="emits('cancelEditWeek', $event)" 
        />      
      <WeekEditFazaHar 
        v-if="editWeek.faza === WeekFaza.Harvest" 
        :week="editWeek" 
        :diary="diary" 
        :is-loading-save="isLoadingSave"
        @updateweek="emits('updateWeek')" 
        @saveweek="saveWeek($event)"
        @canceleditweek="emits('cancelEditWeek', $event)" 
        />         

    </div>

    <div v-else-if="props.diary?.id && !allowEdit">
      <UiNoAccess />
    </div>
  </div>
</template>






<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'
import { WeekFaza, createWeek } from '@/types/diary'

const emits = defineEmits([
  'cancelEditWeek',
  'updateWeek', 
  'removeWeek',
  'createWeekModal'
]);

interface Props {
  week?: Week | null,
  diary: Diary
}

const props = defineProps<Props>();
 

const route = useRoute();
const { $api, $ga, $helper, $convert, $head, $popup, $vartpl, $presaver } = useNuxtApp();
const { t } = useI18n();
const linkWeek = ref<string>(route.params.week as string)
const linkWeekId = ref<number | null>(Number(route.params?.week));
const newWeekFaza = ref<number | null>(useWeek().getWeekFazaFromLink(linkWeek.value));
const isNewWeek = ref<boolean>(newWeekFaza.value === null ? false : true);
  // console.log('letter-', isNewWeek.value);
const originalWeek = ref<Week | null>( useDiary().getWeek(props.diary, linkWeekId.value));
const editWeek = ref<Week | null>(null);

const isLoadingSave = ref(false);
const isLoadedPresaver = ref(false); 
// const presaver = $presaver(props.week?.faza + ':' + props.week?.id, 'week');



const isLoadingPhotos = computed(() => {
  if(editWeek.value.items_photo)
    for (const [key, opt] of editWeek.value.items_photo.entries()) {
      if(opt.is_loading && !opt.is_remove)
        return true;
    }
  return false;
})


 

const prepareData = async () => {

  if(isNewWeek.value){

    originalWeek.value = createWeek();

    // console.log('originalWeek');
    // console.log(originalWeek.value.faza);

    if(newWeekFaza.value == WeekFaza.Germination){
      originalWeek.value.faza = WeekFaza.Germination;
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();

      if(!originalWeek.value.props) {
        originalWeek.value.props = {};
      }
      originalWeek.value.props.germ_date = `${year}-${month}-${day}`;  
      originalWeek.value.item_germination.items_seed = props.diary.items_equip.filter(e => e.category == 'seed');

      originalWeek.value.item_germination.items_seed.map((item) => {
        item.cnt_germ = null;
      });
           
    }else if(newWeekFaza.value == WeekFaza.Vegetation){
      originalWeek.value.faza = WeekFaza.Vegetation;       
      useWeek().fillWeekFromPrev(props.diary.items_week, originalWeek.value);  
    }else if(newWeekFaza.value == WeekFaza.Flowering){
      originalWeek.value.faza = WeekFaza.Flowering;      
      useWeek().fillWeekFromPrev(props.diary.items_week, originalWeek.value);  
    }else if(newWeekFaza.value == WeekFaza.Harvest){
      originalWeek.value.faza = WeekFaza.Harvest;

      if(props.diary.items_week?.length){
        originalWeek.value.days = props.diary.items_week.filter((item) => 
          item.faza === WeekFaza.Vegetation 
          || 
          item.faza === WeekFaza.Flowering).sort((a, b) => b.days - a.days
        )[0]?.days + 1;
      }

      var ref_id = linkWeek.value.substr(1);
      originalWeek.value.item_harvest.item_review_seed.ref_id = ref_id;
      originalWeek.value.item_harvest.item_review_seed.item_product = props.diary.items_equip.filter((item) => item.ref_id == ref_id)[0];
      originalWeek.value.item_harvest.item_review_seed.item_brand = originalWeek.value.item_harvest.item_review_seed.item_product.item_brand;
      //products
      if(editWeekProducts.value){
        originalWeek.value.item_harvest.items_review = editWeekProducts.value.items_review;
      }else{
        var products_data = await loadDataProducts(props.diary.id);
        originalWeek.value.item_harvest.items_review = products_data.items_review;
      }
    } 


    
    // presaver.setup(linkWeek.value + ':' + props.diary.id, 'week')
  }else{ 


    // console.log('originalWeek.value');
    // console.log(originalWeek.value);
    originalWeek.value = JSON.parse(JSON.stringify(originalWeek.value)) as Week;
    

    useWeek().convertUnitsForEdit(originalWeek.value);
    
    
  }

  // console.log('prepare');
  // console.log('originalWeek.value', originalWeek.value);
  // console.log('editWeek.value', editWeek.value);

  // if(presaver.exists() && false){
  //   isLoadedPresaver.value = true;
  //   editWeek.value = presaver.get();
  //   editWeek.value.is_presaved = true;
  //   return;
  // }else{
    editWeek.value = JSON.parse(JSON.stringify(originalWeek.value)) as Week;
  // }

  // console.log('editWeek.value', editWeek.value);
}

const loadDataProducts = async (id) => {
  if(props.week?.faza == WeekFaza.Harvest){
    return {items_review: []};
  }
  let data = await $api.getDiaryHarvestProductsReview(id);  
  return data;
}
 


const presaveClear = function(){  
  isLoadedPresaver.value = false;
  editWeek.value = JSON.parse(JSON.stringify(originalWeek.value));
  console.log('presaveClear', editWeek.value, originalWeek.value);
  // presaver.clearType();
}
 

const changeFazaVeg = (vl) => {
  editWeek.value = vl; 
  editWeek.value.item_flowering = vl.item_vegetation; 
  editWeek.value.item_vegetation = null; 
  editWeek.value.faza = WeekFaza.Flowering; 
}

const changeFazaFlo = (vl) => {
  editWeek.value = vl; 
  editWeek.value.item_vegetation = vl.item_flowering; 
  editWeek.value.item_flowering = null; 
  editWeek.value.faza = WeekFaza.Vegetation;
}





const allowEdit = computed(() => useAuth().isOwner(props.diary.item_user.id));
 

const saveWeek = (week: Week) => {   
    
  if(!useWeek().validateWeek(props.diary.items_week, week)){
    return false;     
  }

  if(isLoadingPhotos.value){
    $popup.error('Wait for photos to load');
    return false;     
  }

  isLoadingSave.value = true;


  var saveWeek = JSON.parse(JSON.stringify(week)) as Week;

  useWeek().convertUnitsForSave(saveWeek);

  var form = saveWeek;              
  if(!form.id){   
    $api.postWeek(props.diary.id, form) 
    .then((res) => {
      isLoadingSave.value = false;
      emits('updateWeek', res.data.id);      
      // presaver.clearType();        
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));
      $popup.error('Error');
      isLoadingSave.value = false;
    });  
  }else{
    $api.putWeek(props.diary.id, form.id, form) 
    .then((res) => {      
      console.log('saved week id');      
      console.log(res.data);      
      emits('updateWeek', res.data.id);    
      isLoadingSave.value = false; 
      // presaver.clearType();        
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));    
      $popup.error('Error');    
      isLoadingSave.value = false;
    });
  }
}



const { status: statusLoadingProduct,  data: editWeekProducts } = await useLazyAsyncData('editWeekProduct' + props.diary.id, async () => await loadDataProducts(props.diary.id));


// const { pending: is_loading_nutrient,  data: editWeek_nutrients } = await useLazyAsyncData('editWeek_nutrients', async () => await loadDataNutrients(props.diary.id));
// const { pending: is_loading_lamp,  data: editWeek_lamps } = await useLazyAsyncData('editWeek_lamps', async () => await loadDataLamps(props.diary.id));
// const { pending: is_loading_tent,  data: editWeek_tents } = await useLazyAsyncData('editWeek_tents', async () => await loadDataTents(props.diary.id));


// console.log('[edit week]');

// watch(() => editWeek, (new_editWeek, old_editWeek, ) => {
//   // if(new_editWeek.value?.id) 

//   var new_data = JSON.parse(JSON.stringify(new_editWeek?.value));

//   if(new_data.item_vegetation){
//     // if(useAuth().getTemperature() == 'c'){
//       // new_data.item_vegetation.air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), new_data.item_vegetation.air_temp);
//     // }
//   }

//   if(JSON.stringify(new_data) != JSON.stringify(originalWeek?.value)){

//     // if(new_data?.item_vegetation){
//     //   console.log('new_data?.item_vegetation');
//     //   console.log(new_data?.item_vegetation.air_temp);
//       // new_data?.item_vegetation.air_temp = 0;//$convert.universal('temperature', 'f', new_editWeek?.value.item_vegetation.air_temp);
//       // new_data?.item_vegetation.air_temp = new_data?.item_vegetation.air_temp;
//     // }
//     // new_data.faza = 0;
    
    
//       console.log('new_data');
//       console.log(new_data);

//     // presaver.set(new_data);
//   }
//   // console.log('new_editWeek');
//   // console.log(new_editWeek);
// }, {deep: true});



onMounted(() => {  
  prepareData();
});


onUnmounted(() => {  
  editWeek.value = null;
});




</script>

<style scoped>
 
</style>
